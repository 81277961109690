.features-tiles-item-image {
	display: inline-flex;
	border-radius: 50%;
	background-color: get-color(primary, 3);
}
.text-color-primary{
	color: color(primary);
}
.tiles-item-inner{
	position:rzelative;
}

.pa-bottom{
	position: absolute;
    bottom: 0;
    left: 0;
    right: 0;
    margin: 0px 7em -15px
}